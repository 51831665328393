.App {
    display: flex;

}

/* To be edited */
.header {
    display: flex;
    padding: 52px 56px 17px 69px;
    justify-content: space-between;
    align-items: center;
    background: #1F78B4;
    overflow: hidden;
}

.content {
    display: flex;
    height: 100%;
}

.text {
    height: 100%;
    width: 50%;
    left: 0;
}

.livePlayerST {
    height: 100%;
    width: 50%;
    right: 0;
}

iframe {
    margin: 50px;
}

.dropdown {
    margin: 27px;
}